/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// Load Roboto typeface
import React from 'react';
import { ThemeProvider } from '@rmwc/theme';
import { createGlobalStyle } from 'styled-components';
import background from './src/images/background.jpg';

// UI styles
import 'normalize.css';
import 'typeface-roboto';
import 'material-icons';
import '@material/theme/dist/mdc.theme.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/button/dist/mdc.button.css';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }
  body {
    background: url(${background}) no-repeat 80% 50% fixed;
    background-size: cover;
  }
`;

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider
      options={{
        primary: 'white',
      }}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  );
};
